import React from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';

import CancelLogo from '../../images/svg/CancelLogo.svg';
import Loading from '../shared/Loading'

const Wrapper = styled.div`
  display: ${(props) => (props.show ? 'flex' : 'none')};
  flex-direction: column;
  height: 100%;
  width: 368px;
  position: fixed;
  top: 0;
  right: 0;
  background-color: #fff;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  transition: transform 0.3s ease-in-out;
  transform: ${(props) => (props.show ? 'translateX(0)' : 'translateX(100%)')};
`;

const Cancel = styled.img`
  cursor: pointer;
  margin-left: auto;
`
const Header = styled.div`
  display: flex;
	border-bottom: 1px solid #0000000D;
  padding: 0 5% 4% 5%;
`
const Title = styled.div`
  font-family: 'GraphikLCGMedium';
  font-size: 24px;
  line-height: 24px;
  margin: 20px;
`
const Body = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5%;
`
const Client = styled.div`
  font-family: 'GraphikLCGSemibold';
  font-size: 14px;
  line-height: 16px;
  color: rgba(0, 0, 0, 0.5);
`
const Name = styled.div`
  font-family: 'GraphikLCGRegular';
  font-size: 16px;
  line-height: 16px;
  margin-left: auto;
`
const Row = styled.div`
  display: flex;
  margin: 16px;
`
const TitleTopic = styled.div`
	font-size: 16px;
	font-family: 'GraphikLCGMedium';
	padding-top: 32px;
  padding: 5%;
`
const AttentionPlease = styled.div`
  width: 320px;
  background-color: #F8F7F8;
  border-radius: 4px;
  border: 1px solid #0000000D;
  margin-left: 24px;
  height: 48px;
  align-items: center;
  font-size: 16px;
  font-family: 'GraphikLCGMedium';
  padding-left: 12px;
  display:flex;
`
const Events = styled.div`
  display: flex;
  flex-direction: column;
`
const EventName = styled.div`
  font-size: 14px;
  font-family: 'GraphikLCGMedium';
  color: black;
`
const EventDate = styled.div`
  font-size: 14px;
  color: #00000080;
`
const Event = styled.div`
  font-size: 10px;
  line-height: 12px;
  padding-left: 16px;
`
const RowEvents = styled.div`
  display: flex;
  margin: 16px 16px 0px 16px;
  justify-content: space-between;
`
const AddButton =  styled.div`
  font-family: 'GraphikLCGMedium';
  font-size: 16px;
  line-height: 16px;
  margin-left: auto;
  cursor: pointer;
  color: #6F2CFF;
`
const Day = styled.div`
  margin-left: auto;
  font-size: 16px;
  font-family: 'GraphikLCGRegular';
  margin-right: 10px;
`
dayjs.locale('ru')

const UserInfo = ({
	setShowInfo, 
	showInfo,
	usersInfo,
  selectedUser,
  setSelectedUser,
  selectedUserZone,
  setSelectedUserZone,
  loadingUserInfo,
  setShowInput,
  showInput,
  setComment,
  comment,
  handleAddComment
}) => {
  const handleComment = (event) => {
    setComment(event.target.value)
  }
  return (
		<Wrapper show={showInfo === true ? true : false}>
      {!loadingUserInfo ? (
       <>
		    <Header>
          <Title>История событий</Title>
          <Cancel 
			  	src={CancelLogo} 
			  	onClick={() => {
            setShowInfo(false)
            setSelectedUser(null)
            setSelectedUserZone(null)
            }} />
        </Header>
        {selectedUserZone === 'redZone' || selectedUser?.trainings?.length > 0 ? (
         <AttentionPlease 
           style={{
            borderTopWidth: 4, 
            borderTopColor: selectedUserZone === 'redZone' ? '#FF5B5B' : selectedUser?.trainings?.length > 0 ? '#FFC83C' : 'transparent',
          }}>
          Требуется внимание
          <Day>День {usersInfo?.day}</Day>
        </AttentionPlease>
        ) : null}
			  <Body>
			  	<Row>
            <Client>Имя</Client>
            <Name>{usersInfo?.user.firstName}</Name>
          </Row>
          <Row>
            <Client>Никнейм</Client>
            <Name>{usersInfo?.user.nickname}</Name>
          </Row>
          <Row>
            <Client>Номер</Client>
            <Name>{usersInfo?.user.phoneNumber}</Name>
          </Row>
          <Row>
            <Client>
              Email
            </Client>
            <Name>{usersInfo?.user.email || 'Отсуствует'}</Name>
          </Row>
          {usersInfo?.commentSales?.length > 0 ? (
            <Row style={{paddingTop: 10}}>
            <Client>Примечание</Client>
            <Name>{usersInfo?.commentSales}</Name>
          </Row>
          ) : (
          <Row style={{paddingTop: 10}}>
            <Client>Примечание</Client>
            {!showInput ? (<AddButton onClick={()=> {setShowInput(true)}}>Добавить</AddButton>) : null}
          </Row>
          )}
          {showInput ? 
          <input          
           value={comment}
           onChange={handleComment} 
           style={{ borderRadius: 2, borderWidth: 1, width: 300, marginLeft: 16, height: 20 }} /> : null}
          {showInput ? 
          (<AddButton 
            style={{marginRight: 16, marginTop: 10}} 
            onClick={()=> {handleAddComment({userId: selectedUser.user._id, values: { comment } })}}>
              Добавить
            </AddButton>) 
          : null}
			  	<TitleTopic>События</TitleTopic>
            <Events>
            {usersInfo?.events.map((obj, i) => (
              <div key={i}>
                <RowEvents>
                  <EventName>
                    {obj.type}
                  </EventName>
                  <EventDate>
                    {dayjs(obj.startTime).format('DD.MM | HH:mm')}
                  </EventDate>
                </RowEvents>
                <Event>
                  {obj.programSetName}, {dayjs(obj.startTime).format('DD, MMMM ,HH:mm')}
                </Event>
              </div>
            ))}
            </Events>
			  </Body>
       </>
        ) : (
        <Loading />
      )}
		</Wrapper>
	)
}

export default UserInfo
